@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flowbite/dist/flowbite.css';


/* src/index.css or src/App.css */

.Toastify__toast-container {
    font-family: 'Arial', sans-serif; /* Set the font family */
  }
  
  .Toastify__toast {
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Padding */
    font-size: 16px; /* Font size */
  }
  
  .Toastify__toast--success {
    background: #4CAF50; /* Success background color */
    color: white; /* Text color */
  }
  
  .Toastify__toast--error {
    background: #F44336; /* Error background color */
    color: white; /* Text color */
  }
  
  .Toastify__toast--info {
    background: #2196F3; /* Info background color */
    color: white; /* Text color */
  }
  
  .Toastify__toast--warning {
    background: #FFC107; /* Warning background color */
    color: black; /* Text color */
  }
  
  .Toastify__toast-body {
    font-size: 14px; /* Text size */
  }
  
  .Toastify__toast-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999; /* Ensure it’s on top of other content */
  }
  